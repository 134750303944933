import React, { Component, createRef } from "react";
import { Button, FlexLine, Input } from "../../style";

import { observer, inject } from 'mobx-react';
import { createProductDetailJson } from "./lib";
import { Modal } from "../../components";
import styled from 'styled-components';
import { axiosPost } from "../../api";
import Icon from "../Icon";
import { toast } from 'react-toastify';

const UploadButton = styled.label`
  display: flex;
  padding: 6px 26px;
  margin-right:20px;
  background-color: #26abff;
  color: #ffffff;
  font-size: 16px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #26abff;
`;

const ImgLine = styled.div`
  display: flex;
  min-height: 200px;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
`

const ImgShow = styled.img`
  width: 200px;
  height: auto;
`

const IconBtn = styled.div`
  cursor: pointer;
  margin:0 20px;
  padding:10px;
`

@inject('createStore')
@observer
class ProductDetail extends Component {

  state = {
    imgList: [],
    imgModalVisible: false,
    btn_loading: false,
    fileList: []
  }

  onToggleModal = () => {
    this.setState((preState) => ({
      imgModalVisible: !preState.imgModalVisible
    }))
  }

  onCreate = () => {
    if (this.state.fileList.length < 1) {
      return
    }
    let { pc, h5 } = createProductDetailJson(this.state.fileList)
    let { initFloorData } = this.props.createStore

    if (!pc || !h5) return

    initFloorData({ h5_json: [h5], pc_json: [pc] })
    toast('方案生成成功', {
      type: 'success'
    })
    this.setState({ fileList: [] })
    this.onToggleModal()
  }

  moveUp = (index) => {
    if (index === 0) {
      return
    }
    let { fileList } = this.state
    let temp = fileList[index]
    fileList[index] = fileList[index - 1]
    fileList[index - 1] = temp
    this.setState({ fileList })
  }

  moveDown = (index) => {
    if (index === this.state.fileList.length - 1) {
      return
    }
    let { fileList } = this.state
    let temp = fileList[index]
    fileList[index] = fileList[index + 1]
    fileList[index + 1] = temp
    this.setState({ fileList })
  }

  delete = (index) => {
    let { fileList } = this.state
    fileList.splice(index, 1)
    this.setState({ fileList })
  }

  handleChange = async (e) => {
    if (!e.target.files) {
      return
    }

    const length = e.target.files.length
    const files = e.target.files

    //根据lenth初始化fileList
    let fileList = new Array(length).fill({ src: '', isLoading: true })
    let preLength = this.state.fileList.length
    this.setState((preState) => ({
      fileList: [...preState.fileList, ...fileList],
      btn_loading: true
    }))

    for (let index = 0; index < length; index++) {
      const element = files[index];
      await this.upload(element, preLength + index)
    }

    this.setState({
      btn_loading: false
    })
  }

  add = (index) => {
    let { fileList } = this.state
    fileList.splice(index + 1, 0, { src: '', isLoading: false })
    this.setState({ fileList })
  }

  onItemChange = (value, index) => {
    //替换相应元素的src
    let { fileList } = this.state
    fileList[index].src = value
    this.setState({ fileList })
  }

  onUploadItem = async (e, index) => {
    if (!e.target.files) {
      return
    }
    let { fileList } = this.state;
    fileList[index] = { src: '', isLoading: true }
    this.setState({ fileList })
    await this.upload(e.target.files[0], index)
  }

  upload = async (file, index) => {

    const fd = new FormData();
    fd.set('file', file);
    fd.set('flag', this.props.createStore.siteId);

    const arg = await axiosPost('/apiproxy/resources', fd)

    let { fileList } = this.state
    fileList[index] = { src: process.env.S3_URL + arg.data.url, isLoading: false }
    await this.setState({ fileList })

  };

  render() {
    const { btn_loading } = this.state
    return (
      <div>
        <div style={{ width: '160px' }}>
          <Button onClick={this.onToggleModal} style={{ marginLeft: '20px' }}>快速图片上传</Button>
        </div>
        {
          this.state.imgModalVisible && <Modal hascloseBtn visible={this.state.imgModalVisible} onClose={this.onToggleModal}>
            <div style={{ width: '80vw', height: '80vh', borderRadius: '20px', overflowY: 'scroll' }}>
              <h2>商品详情页素材快速生成</h2>
              <p style={{ fontSize: '16px', padding: '10px 0' }}>选取商品详情页素材上传，一键生成商品详情PC、H5方案</p>
              <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0', marginBottom: '40px' }}>
                <UploadButton>
                  <input type="file" style={{ display: 'none' }} accept={'image/*'} multiple={true} onChange={this.handleChange} onClick={this.click} disabled={btn_loading} />
                  <span>{btn_loading ? '上传中......' : '上传图片(可多选)'}</span>
                </UploadButton>
                <Button disable={this.state.fileList.length < 1} onClick={this.onCreate}>生成方案</Button>
              </div>
              <div>
                {
                  this.state.fileList.map((item, index) => {
                    return (
                      <ImgLine key={index}>
                        {
                          item.isLoading ? '上传中...' : !item.src ? <UploadButton>
                            <input type="file" style={{ display: 'none' }} accept={'image/*'} multiple={false} onChange={(e) => { this.onUploadItem(e, index) }} onClick={this.click} disabled={btn_loading} />
                            <span>上传图片</span>
                          </UploadButton> : <ImgShow src={item.src} />
                        }
                        <div>
                          <Input style={{ marginLeft: '20px', width: '500px', marginBottom: '20px' }} placeholder="请输入图片链接" value={item.src} type="text" onChange={(e) => { this.onItemChange(e.target.value, index) }} />
                          <FlexLine>
                            <IconBtn onClick={() => { this.moveUp(index) }}>
                              <Icon icon={'icon-arrow-up'} style={{ fontSize: '24px', fontWeight: '800' }} />
                            </IconBtn>
                            <IconBtn onClick={() => { this.moveDown(index) }}>
                              <Icon icon={'icon-arrow-down2'} style={{ fontSize: '24px', fontWeight: '800' }} />
                            </IconBtn>
                            <IconBtn onClick={() => { this.delete(index) }}>
                              <Icon icon={'icon-delete'} style={{ fontSize: '24px', fontWeight: '800' }} />
                            </IconBtn>
                            <IconBtn onClick={() => { this.add(index) }}>
                              <Icon icon={'icon-plus'} style={{ fontSize: '24px', fontWeight: '800' }} />
                            </IconBtn>
                          </FlexLine>
                        </div>
                      </ImgLine>
                    )
                  })
                }
              </div>

            </div>
          </Modal>
        }

      </div>
    );
  }
}

export default ProductDetail;
