import { toLine } from "../../../utils/transformCssPropertyName";

const ExpiryDate = {
  en: "Expiry Date:",
  es: "Fecha de caducidad: ",
  de: "Gültig bis:",
  fr: "Date d’expiration :",
  pt: "Data de expiração:",
  jp: "有効期限",
  ro: "Data de expirare:",
  pl: "Data ważności: ",
  sa: "تاريخ الانتهاء:",
};
const CopyText = {
  en: "Copy code and choose glasses",
  es: "Copia el código y aplica la oferta",
  de: "Code kopieren und Brille auswählen",
  fr: "Copiez le code et choisissez les lunettes",
  it: "Coppia",
  pt: "Copiar o código e aplicar a oferta",
  jp: "コードをコピーしてメガネを選ぶ",
  ro: "Copiați codul și alegeți ochelarii",
  pl: "Skopiuj kod i wybierz okulary",
  sa: "انسخ الرمز واختر النظارات",
};
export default function staticRender({ config, page_id = 0 }) {
  let { id, props = {} } = config;
  id = `${page_id}-${id}`;
  const {
    notNeedEmail = false,
    buttonText = "Get Code",
    hoverButtonText = "Get Code",
    couponCode = "",
    showExperienceTime,
    codeTitle = "Get Code",
    hasnoNumList,
    ruleList = [],
    placeholder = "Enter your email address to get code",
    title,
    editeExpTime,
    styles = {},
    lang = "en",
  } = props;

  const { coupon_btn_color = "#26ABFF" } = styles;

  const result = {
    styles: [
      {
        name: `.pt-h5-coupon-${page_id}`,
        content: `
          .pt-h5-coupon-${page_id} {
            padding: 20px 0;
          }
          .pt-h5-coupon-form-email-input {
            display: block;
            width: 240px;
            outline: none;
            border: 1px solid #E4E4E4;
            box-sizing: border-box;
            padding: 6px 10px;
            color: #333;
            font-size: 12px;
            margin: 0 auto 16px;
            /* placeContent: { color: #999; } */
          }
          .pt-h5-coupon-form-submit-btn {
            border: none;
            font-size: 12px;
            color: #fff;
            line-height: 24px;
            min-width: 100px;
            width: auto;
            text-align: center;
            margin: 0 auto;
            background: #26ABFF;
            display: block;
            border-radius: 2px;
          }
          @keyframes rotate {
            from {
              transform: rotate(-90deg);
              display: block;
            }
            to {
              transform: rotate(0deg);
            }
          }
          @keyframes rotateBack {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(-90deg);
              display: none;
            }
          }
          .pt-h5-coupon-fixed-btn.h5_up_show {
            /* animation: rotate 0.5s; */
            opacity: 1;
            z-index: 9;
          }
          .pt-h5-coupon-fixed-btn.h5_up_hide {
            /* animation: rotateBack 0.5s; */
            opacity: 0;
            z-index: -1;
          }
          .pt-h5-coupon-fixed-btn {
            position: fixed;
            width: 100%;
            left: 0;
            bottom: 0;
            background: #000;
            color: #fff;
            height: 48px;
            line-height: 48px;
            text-align: center;
            opacity: 0;
            z-index: -1;
            font-size: 14px;
          }
          .pt-h5-coupon-modal-form {
            /* padding: 20px 0; */
            padding: 0 0 20px;
          }
          .pt-h5-coupon-modal-form-title {
            text-align: center;
            font-size: 18px;
            font-family: Roboto-Medium;
            color: #333;
            margin-bottom: 20px;
          }
          .pt-h5-coupon-code-info {
            text-align: center;
            padding: 20px 0;
          }
          .pt-h5-code-box {
            width: 160px;
            height: 44px;
            line-height: 44px;
            text-align: center;
            margin: 0 auto 13px;
            font-family: Roboto-Medium;
            font-size: 18px;
            background: rgba(255, 255, 255, 1);
            border: 2px solid rgba(228, 228, 228, 1);
          }
          .pt-h5-time-box {
            text-align: center;
            line-height: 1;
            color: #333;
            font-size: 12px;
            margin-bottom: 15px;
          }
          .pt-h5-copy-btn {
            line-height: 1;
            color: #26abff;
            font-size: 12px;
            cursor: pointer;
          }
          .pt-h5-big-box {}
          .pt-h5-big-box-title {
            color: #333;
            font-size: 14px;
            margin-top: 30px;
            margin-bottom: 15px;
            line-height: 1;
          }
          .pt-h5-big-box-ul {
            padding-left: 20px;
          }
          .pt-h5-big-box-dot {
            list-style: initial;
          }
          .pt-h5-big-box-num {
            list-style: decimal;
          }
          .pt-h5-big-box-li {
            font-size: 12px;
            text-align: left;
            line-height: 1.5;
          }
          .dialog-h5-coupon-msg {
            padding: 40px;
            padding-bottom: 0;
            text-align: center;
            line-height: 30px;
          }
        `,
      },
      {
        name: `.pt-h5-coupon-${id}`,
        content: `
          .pt-h5-coupon-form-submit-btn-${id} {
            background-color: ${coupon_btn_color};
          }
          .pt-h5-coupon-fixed-btn-${id} {
            background-color: ${coupon_btn_color};
          }
        `,
      },
    ],
    html: `
      <div class="pt-h5-coupon-${page_id} pt-h5-coupon-${id}">
        ${
          !notNeedEmail
            ? `
          <!-- 最上方 用户一眼就能看到的那个表单 -->
          <form name="pt-h5-coupon-form-${id}">
            <input
              class="pt-h5-coupon-form-email-input pt-h5-coupon-top-form-email-input-${id}"
              type="email"
              placeholder="${placeholder}"
            />
            <button
              class="pt-h5-coupon-form-submit-btn pt-h5-coupon-form-submit-btn-${id}"
              type="submit"
            >${buttonText}</button>
          </form>
        `
            : ""
        }

        <!-- 悬浮按钮 -->
        <div class="pt-h5-coupon-fixed-btn pt-h5-coupon-fixed-btn-${id} h5_up_hide">${hoverButtonText}</div>

        <!-- 弹窗领取 -->
        <div class="dialog-h5-box pt-h5-coupon-form-modal-${id}">
          <div class="dialog-h5">
            <div class="dialog-h5-body">
              <svg viewBox="0 0 1000 1000" class="dialog-h5-btn-close">
                <path d="
                          M 0 0 L 60 0 L 500 440 L 940 0 L 1000 0
                          L 1000 60 L 560 500 L 1000 940 L 1000 1000
                          L 940 1000 L 500 560 L 60 1000 L 0 1000
                          L 0 940 L 440 500 L 0 60 Z
                        " fill="#333" />
              </svg>
              <div class="dialog-h5-coupon-body">
                <!-- 弹窗中的表单 -->
                <div>
                  ${
                    title
                      ? `
                    <div class="pt-h5-coupon-modal-form-title">${title}</div>
                  `
                      : ""
                  }
                  <form class="pt-h5-coupon-modal-form pt-h5-coupon-modal-form-${id}">
                    <input
                      class="pt-h5-coupon-form-email-input pt-h5-coupon-modal-form-email-input-${id}"
                      type="email"
                      placeholder="${placeholder}"
                    />
                    <button
                      class="pt-h5-coupon-form-submit-btn pt-h5-coupon-form-submit-btn-${id}"
                      type="submit"
                    >${buttonText}</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <!-- 显示code -->
        <div class="dialog-h5-box pt-h5-coupon-code-modal-${id}">
          <div class="dialog-h5">
            <div class="dialog-h5-body">
              <svg viewBox="0 0 1000 1000" class="dialog-h5-btn-close">
                <path d="
                          M 0 0 L 60 0 L 500 440 L 940 0 L 1000 0
                          L 1000 60 L 560 500 L 1000 940 L 1000 1000
                          L 940 1000 L 500 560 L 60 1000 L 0 1000
                          L 0 940 L 440 500 L 0 60 Z
                        " fill="#333" />
              </svg>
              <div class="dialog-h5-coupon-body">
                <!-- 弹窗展示code相关信息 -->
                <div class="pt-h5-coupon-code-info">
                  <div class="pt-h5-code-box pt-h5-code-box-${id}"><b>{{CODE}}</b></div>
                  <div class="pt-h5-time-box pt-h5-time-box-${id}">{{EXPIRE_DATE_TIME}}</div>
                  <div class="pt-h5-copy-btn pt-h5-copy-btn-${id}">{{COPY_TEXT}}</div>
                  <div class="pt-h5-big-box">
                    <div class="pt-h5-big-box-title"><b>${codeTitle}</b></div>
                    <ul class="pt-h5-big-box-ul">
                      ${ruleList
                        .map((item, index) => {
                          return `
                          <li class="pt-h5-big-box-li ${
                            hasnoNumList
                              ? "pt-h5-big-box-dot"
                              : "pt-h5-big-box-num"
                          }">${item}</li>
                        `;
                        })
                        .join(" ")}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <!-- 提示msg -->
        <div class="dialog-h5-box pt-h5-coupon-msg-modal-${id}">
          <div class="dialog-h5">
            <div class="dialog-h5-body">
              <svg viewBox="0 0 1000 1000" class="dialog-h5-btn-close">
                <path d="
                          M 0 0 L 60 0 L 500 440 L 940 0 L 1000 0
                          L 1000 60 L 560 500 L 1000 940 L 1000 1000
                          L 940 1000 L 500 560 L 60 1000 L 0 1000
                          L 0 940 L 440 500 L 0 60 Z
                        " fill="#333" />
              </svg>
              <div class="dialog-h5-coupon-body dialog-h5-coupon-msg">
                {{MSG}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="h5_hover_button_line_${id}"></div>
      <script>
        (function() {
          var fixedBtn = document.querySelector('.pt-h5-coupon-fixed-btn-${id}'); // 悬浮按钮
          var emailFromTopForm; // 用户输入的邮箱
          var emailFromModalForm; // 用户输入的邮箱

          var apiResult; // 领取code接口返回的信息

          function getElementTopLeft(obj) {
            var top = 0;
            while (obj) {
                top += obj.offsetTop;
                obj = obj.offsetParent;
            }
            return { top };
          }

          function handleSubmit(lang, email, elBtn, cb) {
            function disabledButton(el) {
              el.setAttribute('disabled', 'true');
              el.style.background = '#ccc';
              el.innerText = 'loading...';
            }
            function activeButton(el) {
                el.removeAttribute('disabled');
                el.style.background = oldBg;
                el.innerText = oldBtnText;
            }

            // var emailReg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,6})$/;
            if (!email) return false
            var oldBtnText = elBtn.innerText
            var oldBg = getComputedStyle(elBtn)['background-color'];

            var scheme_id = '826'
            var group_scheme_id = '380'
            var argsEl = document.querySelector(".UP_config_html")
            if (argsEl) {
              scheme_id = argsEl.getAttribute('data-scheme_id')
              group_scheme_id = argsEl.getAttribute('data-group_scheme_id')
            }
            var postUrl = '/remoteapi/common/get/coupon';
            var xhr = new XMLHttpRequest();
            xhr.open('POST', postUrl);
            xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            xhr.setRequestHeader("accept-h5-language", "${lang}");
            xhr.send(\`email_address=\${email}&scheme_id=\${scheme_id}&group_scheme_id=\${group_scheme_id}\`);
            disabledButton(elBtn);
            xhr.onreadystatechange = function () {
              if (xhr.readyState == 4) {
                  activeButton(elBtn);
                  if (xhr.status === 200) {
                      const data = JSON.parse(xhr.responseText);
                      cb && cb(data);
                  }
              };
            }
          }

          function openCodeModalAndClearEmail(coupon_code, expire_date, clearEmail, emailSource) {
            apiResult = { code: coupon_code }
            document.querySelector('.pt-h5-code-box-${id}').querySelector('b').innerText = coupon_code
            document.querySelector('.pt-h5-copy-btn-${id}').innerText = '${
      CopyText[lang]
    }'
            ${(() => {
              if (showExperienceTime) {
                return `
                  if (expire_date) {
                    document.querySelector('.pt-h5-time-box-${id}').innerText = '${ExpiryDate[lang]} ' + expire_date
                  } else {
                    document.querySelector('.pt-h5-time-box-${id}').style.display = 'none'
                  }
                `;
              } else if (editeExpTime) {
                return `
                  if (!expire_date) {
                    document.querySelector('.pt-h5-time-box-${id}').innerText = '${ExpiryDate[lang]} ${editeExpTime}'
                  } else {
                    document.querySelector('.pt-h5-time-box-${id}').style.display = 'none'
                  }
                `;
              } else {
                return `document.querySelector('.pt-h5-time-box-${id}').style.display = 'none'`;
              }
            })()}
            document.querySelector('.pt-h5-coupon-code-modal-${id}').classList.add('open')
            if (clearEmail) {
              if (emailSource === 'top') {
                emailFromTopForm = ''
                document.querySelector('.pt-h5-coupon-top-form-email-input-${id}').value = ''
              } else if (emailSource === 'modal') {
                emailFromModalForm = ''
                document.querySelector('.pt-h5-coupon-modal-form-email-input-${id}').value = ''
              }
            }
          }

          function afterCallApi(data, source) {
            const { coupon_code = '', expire_date = '', special_code, special_expire_date, status, msg } = data;
            if (coupon_code && expire_date) {
              openCodeModalAndClearEmail(coupon_code, expire_date, true, source)                  
            } else if (status == 0 && special_code && special_expire_date) {
              openCodeModalAndClearEmail(special_code, special_expire_date, true, source)                  
            } else if (msg) {
              document.querySelector('.pt-h5-coupon-msg-modal-${id} .dialog-h5-coupon-body').innerText = msg
              document.querySelector('.pt-h5-coupon-msg-modal-${id}').classList.add('open')
            }
          }

          ${
            !notNeedEmail
              ? `
            // top form 用户输入邮箱事件
            document.querySelector('.pt-h5-coupon-top-form-email-input-${id}').onchange = function(e) {
              emailFromTopForm = e.target.value
            }
            // 用户提交最上边的表单的事件
            document.querySelector('form[name="pt-h5-coupon-form-${id}"]').onsubmit = function(e) {
              e.preventDefault();
              handleSubmit('${lang}', emailFromTopForm, e.target.querySelector('button'), function(data) {
                afterCallApi(data, 'top')
              })
            }
          `
              : ""
          }

          // modal 表单提交事件
          document.querySelector('.pt-h5-coupon-modal-form-${id}').onsubmit = function(e) {
            e.preventDefault();
            handleSubmit('${lang}', emailFromModalForm, e.target.querySelector('button'), function(data) {
              document.querySelector('.pt-h5-coupon-form-modal-${id}').classList.remove('open')
              afterCallApi(data, 'modal')
            })
          }

          // 悬浮按钮点击事件监听
          fixedBtn.onclick = function() {
            ${
              notNeedEmail
                ? `
              openCodeModalAndClearEmail('${couponCode}', null)
            `
                : `
              document.querySelector('.pt-h5-coupon-form-modal-${id}').classList.add('open')
            `
            }
          }

          // modal form 用户输入邮箱事件
          document.querySelector('.pt-h5-coupon-modal-form-email-input-${id}').onchange = function(e) {
            emailFromModalForm = e.target.value
          }

          // 给window绑定scroll事件监听，方便展示/隐藏悬浮按钮
          window.addEventListener('scroll', function() {
            var windowScrollTop = document.documentElement.scrollTop || document.body.scrollTop
            var flagDiv = document.querySelector('#h5_hover_button_line_${id}');
            var flagDivTop = flagDiv && getElementTopLeft(flagDiv).top || 0;
            if (windowScrollTop > flagDivTop) {
              fixedBtn.classList.remove('h5_up_hide')
              fixedBtn.classList.add('h5_up_show')
            } else {
              fixedBtn.classList.remove('h5_up_show')
              fixedBtn.classList.add('h5_up_hide')
            }
          })

          // fixed按钮点击出现的那个弹窗关闭按钮点击事件
          document.querySelector('.pt-h5-coupon-form-modal-${id} .dialog-h5-btn-close').onclick = function() {
            document.querySelector('.pt-h5-coupon-form-modal-${id}').classList.remove('open');
          }

          function closeCodeModal() {
            document.querySelector('.pt-h5-coupon-code-modal-${id}').classList.remove('open');
            document.querySelector('.pt-h5-coupon-msg-modal-${id}').classList.remove('open')
          }
          // 领取code成功出现的弹窗关闭按钮点击事件
          document.querySelector('.pt-h5-coupon-code-modal-${id} .dialog-h5-btn-close').onclick = closeCodeModal
          document.querySelector('.pt-h5-coupon-msg-modal-${id} .dialog-h5-btn-close').onclick = closeCodeModal

          document.querySelector('.pt-h5-copy-btn-${id}').onclick = function(e) {
            try {
              window.Copy(\`<div>\${apiResult && apiResult.code ? apiResult.code : '${couponCode}'}</div>\`)
            } catch (e) {
              console.log('copy code error -> ', e)
            }
            closeCodeModal()
          }
        })()
      </script>
    `,
  };
  return result;
}
