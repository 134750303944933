import React from 'react';
import styled from 'styled-components';

const HrefBox = styled.a(({styles={}})=>{
    return{
        cursor: 'pointer',
        ...styles
    }
})
const Img = styled.img(({ styles = {}}) => ({
    width: '100%',
    minHeight:'100px',
    maxWidth: '430px',
    ...styles
}))

const Container = styled.div(({ styles }) => ({
    display: 'flex',
    flexDirection:'column',
    alignItems:'center',
    width: '100%',
    padding:'30px',
    boxSizing: 'border-box',
    fontFamily: 'Roboto-Regular',
    ...styles
}))



const PriceLine = styled.div({
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
})

const PrePrice = styled.s({
    color:'#666666',
    fontSize:'18px',
    marginRight:'5px'
})

const Price = styled.span({
    color: '#333',
    fontSize: '18px',
    fontFamily:'Roboto-Medium'
})

const ProductName = styled.div({
    marginTop: '10px',
    fontSize:'18px',
    textAlign:'center'
})

const Button = styled.div(({ btnStyle = {}, btn_hover_style ={}}) => {
    return {
        borderRadius:'4px',
        textAlign:'center',
        lineHeight:'52px',
        cursor:'pointer',
        marginTop: '10px',
        color:'#fff',
        fontSize:'18px',
        ...btnStyle,
        "&:hover":{
            ...btn_hover_style
        }
    }
})

function PcImg ({ 
    imgSrc,
    price,
    pre_price,
    product_name,
    href,
    btn_size='lg',
    btn_color='#000',
    btn_text = 'Shop Now >',
    isShowPrice = true,
    isShowName = true,
    isShowBtn = true,
    product_style,
    isBorderBtn = false
}){
    if(!imgSrc){
        imgSrc = 'https://df5apg8r0m634.cloudfront.net/images/2023/0428/10f199161ca9a9605534e4211763c1af.jpg'
    }

    let btn_hover_style = {
        backgroundColor: btn_color,
        color: isBorderBtn ? '#fff' : 'rgba(255,255,255,0.8)'
    }
    let btnStyle = {
        width: '255px',
        lineHeight: '52px',
        fontSize:'18px',
        backgroundColor: isBorderBtn ? '#fff' : btn_color,
        color: !isBorderBtn ? '#fff' : btn_color,
        border: isBorderBtn ? `1px solid ${btn_color}` : 'unset'
    }
    if (btn_size === 'md'){
        btnStyle = {
            ...btnStyle,
            width: '160px',
            lineHeight: '44px',
            fontSize: '16px'
        }
    }
    if (btn_size === 'ms') {
        btnStyle = {
            ...btnStyle,
            width: 'unset',
            lineHeight: 'unset',
            fontSize: '14px',
            padding:'5px 15px'
        }
    }
    if (btn_size === 'auto') {
        btnStyle = {
            ...btnStyle,
            width: 'unset',
            lineHeight: 'unset',
            fontSize: '16px',
            padding: '10px 40px'
        }
    }
    return (
        <Container>
            <HrefBox
                href={href ? href : ''}
                onClick={(e) => { !href && e.preventDefault() }} target='_blank'>
                <Img src={imgSrc} /></HrefBox>
            {isShowPrice && <PriceLine>
                {pre_price && <PrePrice>{pre_price}</PrePrice>}
                {price && <Price>{price}</Price>}
            </PriceLine>}
            {isShowName && <ProductName>
                {product_style}<span style={{ fontFamily:'Roboto-Medium'}}>{product_name}</span>
            </ProductName>}
            {isShowBtn && <HrefBox
                href={href ? href : ''}
                onClick={(e) => { !href && e.preventDefault() }} target='_blank'>
                <Button
                    btnStyle={btnStyle}
                    btn_hover_style={btn_hover_style}
                >{btn_text}</Button>
            </HrefBox>}
        </Container>
    )
}

export default PcImg;