import React, { useLayoutEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import styled from "styled-components";
import { S3_URL } from "../../api";
import Icon from "../Icon";
import getImgSrcByVideoUrl from "../../utils/getImgSrcByVideoUrl";

const MyItem = styled.div`
  position: relative;
  width: 160px;
  height: 160px;
  cursor: pointer;
  margin: 4px;
  background: #ffffff url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAABFUExURf///9HR0dHR0v/+//7//9HS0v///tLS0tLS0dLR0tLR0dHS0dLS0/7+/v7//tPS0tPS0dHS0/7+/9HT0dHR0//+/tHT0lKUoFgAAACZSURBVBjTNY/bGsQwBISJyKDdHvb0/o+6pFsXvhDzD2TgNbAYZYxnptVMHY5O0rNFZA0tFFrvGcYIAEFSrY3IU6DKxehSEwy1ZjjpMwWDYNjhEbN8FOPNL08I9TSRmywbuX2PhhVXIz89QYuy/+vRoZ5KzgmR6ZbwaDAto14S9ROWN0x97eiGvCIltxHvScXi1zqjE5oiDuAHho4EsWM9SIEAAAAASUVORK5CYII=") repeat center;
`

const MyDelete = styled.button`
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  z-index:9;
`

const MyView = styled.button`
  position: absolute;
  top: 2px;
  right: 30px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  z-index:9;
`

const MyImg = styled.div`
  background-image: ${props => `url('${props.backgroundImage}')`};
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-bottom: 100%;
`

const MyFile = styled.div`
  background-image: ${props => `url('${props.backgroundImage}')`};
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-bottom: 100%;
`

const MyName = styled.p`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  padding: 8px;
  box-sizing: border-box;
  text-overflow: ellipsis;
  font-size: 12px;
  background: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,1));
  color: #ffffff;
`

function FileItem (props) {
  const [thumbnail, setThumbnail] = useState('');

  const imgUrl = `${S3_URL}${props.url}`;

  const onView = (e) => {
    e.stopPropagation()
    e.preventDefault()
    window.open(imgUrl, '_blank')
  };

  const onDelete = async (e) => {
    e.stopPropagation()
    e.preventDefault()
    await props.onDelete(props.id);
  }

  const onSet = () => {
    props.onSelect(props.fileStore.getFileById(props.id))
  }

  useLayoutEffect(() => {
    const videoThumbnail = sessionStorage.getItem(imgUrl)
    if (videoThumbnail) {
      setThumbnail(videoThumbnail)
    } else {
      getImgSrcByVideoUrl(imgUrl).then(thumbnail => {
        setThumbnail(thumbnail)
        sessionStorage.setItem(imgUrl, thumbnail);
      })
    }
  }, [imgUrl]);

  return (
    <MyItem
      title={props.name}
      onClick={onSet}
    >
      {props.type === 1 ? <MyImg backgroundImage={imgUrl} /> : <MyFile backgroundImage={thumbnail} />}
      <MyDelete onClick={onDelete}>
        <Icon icon="icon-delete" style={{ textShadow: '#ffffff 1px 1px 1px' }} />
      </MyDelete>
      <MyView onClick={onView}>
        <Icon icon={props.type === 1 ? 'iconzoomin' : 'icon-video'} style={{ textShadow: '#ffffff 1px 1px 1px' }} />
      </MyView>
      <MyName>{props.name}</MyName>
    </MyItem>
  );
}

export default inject('fileStore')(observer(FileItem))
