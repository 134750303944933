import { observable, action, runInAction } from 'mobx'
import { axiosGet } from '../api'

class SiteStore {
  @observable
  sites = []

  @action
  getSitesList = async () => {
    const { data } = await axiosGet('/apiproxy/sites/list')
    const sites = []
    Object.keys(data.sites).forEach(key => {
      sites.push({
        site_id: Number(key),
        policiesKey: getSitePoliciesKey(Number(key)),
        name: data.sites[key]
      })
    })
    runInAction(() => {
      this.sites = sites
    })
    return data
  }

  @action
  getSiteName = id => {
    id = Number(id)
    const site = this.sites.find(v => v.site_id === id)
    return site ? site.name : ''
  }
}

export default SiteStore

function getSitePoliciesKey(site) {
  switch (site) {
    case 1:
      return 'cms.site.site_USA'
    case 2:
      return 'cms.site.site_UK'
    case 4:
      return 'cms.site.site_AU'
    case 5:
      return 'cms.site.site_DE'
    case 3:
      return 'cms.site.site_ES'
    case 6:
      return 'cms.site.site_black'
    case 7:
      return 'cms.site.site_IT'
    case 8:
      return 'cms.site.site_FR'
    case 55:
      return 'cms.site.site_optieast'
    case 56:
      return 'cms.site.site_diamoda'
    case 12:
      return 'cms.site.site_12'
    case 13:
      return 'cms.site.site_13'
    case 15:
      return 'cms.site.site_15'
    case 16:
      return 'cms.site.site_16'
    case 17:
      return 'cms.site.site_17'
    case 18:
      return 'cms.site.site_18'
    case 19:
      return 'cms.site.site_19'
    case 20:
      return 'cms.site.site_20'
    default:
      return ''
  }
}
