import React from 'react';
import styled from 'styled-components';
import { axiosPost } from '../../api';
import {inject, observer} from "mobx-react";
import {toast} from "react-toastify";

const Button = styled.label`
  display: flex;
  padding: 6px 26px;
  margin: 10px auto;
  background-color: #26abff;
  color: #ffffff;
  font-size: 16px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #26abff;
`;

function FileUploader(props) {

  const { filesNumber,maxNumber = 200 } = props

  const [isLoading, setIsLoading] = React.useState(false);

  const click = (e) => {
    e.target.value = null;
  }

  const upload = (e) => {
    if (filesNumber >= maxNumber){
      return toast(`您的图库容量已达到上限`, {
        type: 'error',
        position: 'top-left'
      });
    }
    const size = e.target.files[0].size / 1024 / 1024
    if (size > (props.video ? 20 : 2)) {
      return toast(`上传文件过大 最大允许${props.video ? 20 : 2}MB`, {
        type: 'error',
        position: 'top-left'
      });
    }

    setIsLoading(true);
    const fd = new FormData();
    fd.set('file', e.target.files[0]);
    fd.set('flag', props.createStore.siteId);
    console.log(e.target.files[0]);
    axiosPost('/apiproxy/resources', fd).then(() => {
      return props.fileStore.getFileList(props.createStore.siteId);
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <Button>
      <input type="file" style={{display: 'none'}} accept={props.video ? 'video/*' : 'image/*'} multiple={false} onChange={upload} onClick={click} disabled={isLoading} />
      <span>{isLoading ? '上传中...' : props.video ? '上传视频' : '上传图片'}</span>
    </Button>
  )
}

export default inject('createStore', 'fileStore')(observer(FileUploader));
