import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import PageComponentsMachine from '../../pageComponents/pageComponentsMachine';
import Icon from '../Icon';
import { FlexLine } from '../../style';
import FileList from "../../components/FileList/FileList";
import { S3_URL } from '../../api';
import FileUploader from "../Input/FileUploader";

import PageLeftNav from "./PageLeftNav";


@inject('createStore','viewStore','fileStore')
@observer
class PageLeft extends Component {

    state = {
        list_0_show: true,
        list_1_show:true,
        list_2_show: true,
        isShowNav: false,
        right_content_show:true
    }

    
    get componentsList(){
        const { pageFlag, pcFlag, type, emailFlag, isTemplate} = this.props.createStore;
        let { PcComponentsList, H5ComponentsList, EmailComponentsList} = PageComponentsMachine;

        if (isTemplate && type === 2){
            if (!PcComponentsList[2].list.find(item => item.name === 'PcAlertCoupon')){
                PcComponentsList[2].list.push({
                    text: '弹窗code',
                    icon: 'icon-list',
                    name: 'PcAlertCoupon'
                })
            }
            if (!PcComponentsList[2].list.find(item => item.name === 'PCCodeContainer')) {
                PcComponentsList[2].list.push({
                    text: '新用户code展示',
                    icon: 'icon-list',
                    name: 'PCCodeContainer'
                })
            }
            if (!H5ComponentsList[2].list.find(item => item.name === 'H5AlertCoupon')) {
                H5ComponentsList[2].list.push({
                    text: '弹窗code',
                    icon: 'icon-list',
                    name: 'H5AlertCoupon'
                })
            }
            if (!H5ComponentsList[2].list.find(item => item.name === 'H5CodeContainer')) {
                H5ComponentsList[2].list.push({
                    text: '新用户code展示',
                    icon: 'icon-list',
                    name: 'H5CodeContainer'
                })
            }
        }
        if (pageFlag === emailFlag){
            return EmailComponentsList
        }
        // if(isTemplate && PcComponentsList instanceof Array && H5ComponentsList instanceof Array){
        //     PcComponentsList[2].list.forEach((item,index) => {
        //         if(item.name == "PcProduct"){
        //             PcComponentsList[2].list.splice(index, 1)
        //         }
        //     })
        //     H5ComponentsList[2].list.forEach((item,index) => {
        //         if(item.name == "H5Product"){
        //             H5ComponentsList[2].list.splice(index, 1)
        //         }
        //     })
        // }
        return pageFlag === pcFlag ? PcComponentsList : H5ComponentsList;
    }

    toggerComponentsList = (idx)=>{
        if(idx === 0){
            this.setState(preState =>({
                list_0_show: !preState.list_0_show
            }))
        }
        if (idx === 1) {
            this.setState(preState => ({
                list_1_show: !preState.list_1_show
            }))
        }
        if (idx === 2) {
            this.setState(preState => ({
                list_2_show: !preState.list_2_show
            }))
        }
    }

    selectImg = (src) =>{
        const { updateDataProps, seletedData } = this.props.createStore;
        const { onCallback } = this.props.viewStore;

        if (seletedData.name === 'PcImg' || seletedData.name === 'H5Img' || seletedData.name === 'Img'){
            updateDataProps('imgSrc', S3_URL + src.url);
            updateDataProps('imgName', src.name);
        }else{
            onCallback && onCallback(S3_URL + src.url)
        }
    }

    selectVideo = (src) => {
        const { updateDataProps, seletedData } = this.props.createStore;

        if (seletedData.name === 'PcVideo' || seletedData.name === 'H5Video') {
            updateDataProps('videoSrc', S3_URL + src.url);
            updateDataProps('videoName', src.name);
        }
    }

    onToggerNavShow = ()=>{
        this.setState((preState)=>({
            isShowNav: !preState.isShowNav
        }))
    }

    onToggerContentShow = () => {
        this.setState((preState) => ({
            right_content_show: !preState.right_content_show
        }))
    }

    render() {
        console.log(this.componentsList, 12312);
        const { siteNav, setSiteView } = this.props.viewStore;
        const { type,isTemplate } = this.props.createStore

        const { isShowNav, right_content_show } = this.state

        return (
            <Fragment>
                <Div>
                    <FlexLine justifyContent={'flex-start'} align = {'flex-start'} style={{height:'100%'}}>
                        <SideLeft>
                            <div>
                                {(type !== 2 || isTemplate) && <SideItem onClick={() => { 
                                    this.setState({
                                        isShowNav: false,
                                        right_content_show: true
                                    }) 
                                    setSiteView('page') }} isActive={siteNav === 'page'}>页<br />面<br />元<br />素</SideItem>}
                                <SideItem onClick={() => { 
                                    this.setState({
                                        isShowNav: false,
                                        right_content_show: true
                                    }) 
                                    setSiteView('image') }} isActive={siteNav === 'image'}>图<br />库</SideItem>
                                {(type === 1 || isTemplate) && <SideItem onClick={() => { 
                                    this.setState({
                                        isShowNav: false,
                                        right_content_show: true
                                    }) 
                                    setSiteView('video') }} isActive={siteNav === 'video'}>视<br />频</SideItem>}
                            </div>
                            <SideLeftBottom>
                                <SideLeftBottomItem onClick={this.onToggerNavShow}>
                                    <Icon icon="icon-filter" style={{ fontSize: '28px', color: isShowNav ? '#26ABFF' : '#333' }} />
                                </SideLeftBottomItem>
                                <SideLeftBottomItem onClick={this.onToggerContentShow}>
                                    <Icon icon={right_content_show ? "icon-arrow-left" : 'icon-arrow-right'} style={{ fontSize: '28px' }} />
                                </SideLeftBottomItem>
                            </SideLeftBottom>
                        </SideLeft>
                        <ScrollDiv right_content_show={right_content_show}>
                            {isShowNav ? <ScrollContainer right_content_show={right_content_show}>
                                <PageLeftNav/>
                            </ScrollContainer> : <ScrollContainer right_content_show={right_content_show}>
                                {siteNav === 'page' && <div style={{ paddingTop: '20px' }}>
                                    {this.componentsList.map((componet, idx) => {
                                        const { title } = componet;
                                        const is_active = this.state[`list_${idx}_show`]
                                        return <React.Fragment key={idx}>
                                            <FlexLine
                                                onClick={() => {
                                                    this.toggerComponentsList(idx)
                                                }}
                                                style={{
                                                    justifyContent: 'space-between',
                                                    padding: '5px 10px',
                                                    marginBottom: '20px',
                                                    cursor: 'pointer',
                                                    borderBottom: '1px solid #CCCCCC'
                                                }}>
                                                <ComponetTitle>{title}</ComponetTitle>
                                                <Icon icon={is_active ? "icon-arrow-down" : "icon-arrow-top"} />
                                            </FlexLine>
                                            {
                                                is_active && <Wrap>
                                                    {
                                                        componet.list.map((item, key) => {
                                                            const { name, text, icon } = item;
                                                            return (
                                                                <ComponetButton
                                                                    key={key}
                                                                    draggable
                                                                    onDragStart={(e) => {
                                                                        e.dataTransfer.setData("dragComponentName", name);
                                                                    }}
                                                                >
                                                                    <Icon icon={icon} />
                                                                    <ComponetText>{text}</ComponetText>
                                                                </ComponetButton>
                                                            )
                                                        })
                                                    }
                                                </Wrap>
                                            }

                                        </React.Fragment>
                                    })}
                                </div>}
                                {
                                    siteNav === 'image' && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <div>
                                            <FileUploader filesNumber={this.props.fileStore.fileList.length} maxNumber={this.props.createStore.siteId === 'template' ? 300 : 200} />
                                        </div>
                                        <div>{this.props.fileStore.fileList.length}/{this.props.createStore.siteId === 'template' ? 300 : 200}</div>
                                        <div>
                                            <input
                                                style={{ margin: '10px auto' }}
                                                type="text"
                                                value={this.props.fileStore.searchText}
                                                onChange={(e) => {
                                                    this.props.fileStore.onSearchChange(e.target.value);
                                                }}
                                                placeholder="请输入关键字搜索"
                                            />
                                        </div>
                                        <FileList type={1} onSelect={this.selectImg} />
                                    </div>
                                }
                                {
                                    siteNav === 'video' && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <div>
                                            <FileUploader video={true} filesNumber={this.props.fileStore.fileList.length} maxNumber={this.props.createStore.siteId === 'template' ? 300 : 200} />
                                        </div>
                                        <div>{this.props.fileStore.fileList.length}/{this.props.createStore.siteId === 'template' ? 300 : 200}</div>
                                        <div>
                                            <input
                                                style={{ margin: '10px auto' }}
                                                type="text"
                                                value={this.props.fileStore.searchText}
                                                onChange={(e) => {
                                                    this.props.fileStore.onSearchChange(e.target.value);
                                                }}
                                                placeholder="请输入关键字搜索"
                                            />
                                        </div>
                                        <FileList type={[2, 3]} onSelect={this.selectVideo} />
                                    </div>
                                }
                            </ScrollContainer>}
                        </ScrollDiv>
                    </FlexLine>
                </Div>
            </Fragment>
        );
    }
}
// 获取模块命

export default PageLeft;



const ScrollDiv = styled.div({
    height: '100%',
    width: props => props.right_content_show ? '180px' : '0',
    transform: props => props.right_content_show ? 'translateX(0);' : 'translateX(-200px);',
    transition: ' all 0.3s',

})

const ScrollContainer = styled.div({
    height: '100%',
    overflow: 'auto',
    scrollbarWidth: 'none',/* Firefox */
    '&::-webkit-scrollbar': {
        display: 'none', /* Chrome Safari */
        scrollbarWidth: 'none'
    },
    opacity: props => props.right_content_show ? '1' : '0',
})

const SideLeftBottom = styled.div({
    paddingBottom:'60px',
    width:'100%'
})

const SideLeftBottomItem = styled.div({
    width:'100%',
    textAlign:'center',
    padding:'10px 0',
    cursor: 'pointer'
})


const SideLeft = styled.div({
    width:'40px',
    height:'100%',
    backgroundColor:'#E0E0E0',
    paddingTop:'20px',
    flex: 'none',
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-between',
    zIndex:9
})

const SideItem = styled.div({
    width:'100%',
    padding:'20px 0',
    textAlign:'center',
    cursor:'pointer',
    color: props => props.isActive ? '#26ABFF' : '#333',
    borderBottom:'1px solid #A2A2A2'
})

const Div = styled.div({
    background: '#fff',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    "&::-webkit-scrollbar": {
        display: 'none'
    }
});

const Wrap = styled.div({
    display: 'flex',
    flexWrap: 'wrap',
    padding:'0 5px',
    justifyContent: 'space-between'
})
const ComponetButton = styled.div({
    width: '43%',
    textAlign: 'center',
    padding: '5px 0',
    margin: '5px',
    marginBottom: '20px',
    boxSizing: 'border-box',
    background: '#F0F0F0',
    borderRadius: '2px',
    fontSize: '14px',
    cursor:'move'
});

const ComponetTitle = styled.p({
    margin: '5px 0'
})


const ComponetText = styled.p({
    margin: '5px 0'
})
